import { getApiBaseUrl } from '@/common/env/app-url'
import { LocalStorageService } from '@/services/local-storage.service'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

/**
 * 导出Excel通用方法
 * @getUrl
 * @parse
 */
export const ExportExcel = function (getUrl: string, parse: object) {
  let requestData = ''
  for (const key in parse) {
    requestData += `${key}=${parse[key]}&`
  }
  const injector = useReflectiveInjector([LocalStorageService]);
  const localStorageService = injector.get(LocalStorageService);

  const url = `${getApiBaseUrl()}${getUrl}?${requestData}fstoken=${localStorageService.getUserToken()}`;
  const linkElement = document.createElement('a');
  try {
    linkElement.setAttribute('href', url);
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
    console.log(ex);
  }
}
