import { Injectable } from '@tanbo/vue-di-plugin'

import { HttpService } from '@/services/http.service'

import { getNewObject } from '@/utils/time'

export interface settlementFormTy {
  /** 业务名称 */
  soft_type: number
  /** 年月 */
  yearMonth: string
}
export interface channelSoftInfosTY {
  softid: number
  softname: string
}

export interface ChannelRequestData {
  data?: channelSoftInfosTY[]
}

export interface settlementTableTY {
  remain: string
  settle_daily: number
  settle: number
  price: string
  cash: string
}

export interface settlementTableRequestData {
  code: number
  data: settlementTableTY[]
  msg: string
}

/* 表单规范 */
export const settlementFormRulesData = {
  soft_type: [
    {
      required: true,
      message: '请选择业务名称',
      trigger: 'change',
    },
  ],
  yearMonth: [
    {
      required: true,
      message: '请选择年月',
      trigger: 'change',
    }
  ]
}

@Injectable()
export class SettlementService {
  constructor (private http: HttpService) {}

  doGetSttlementInfo (params: settlementFormTy) {
    return this.http.post<settlementTableRequestData>('/get_settle_price_info.json', getNewObject(params)).then((response) => {
      return response
    })
  }
}
