/**
 * 日期的计算
 * @description 获得前一天的数据
 * @param AddDayCount
 */
 export function getDateStr (AddDayCount: number, first?: boolean) {
  const dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount) // 获取AddDayCount天后的日期
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1; // 获取当前月份的日期
  const d = dd.getDate();
  let mture, dture;
  m < 10 ? mture = '0' + m : mture = m;
  d < 10 ? dture = '0' + d : dture = d;
  if (first) {
    return y + '-' + mture + '-' + '01'
  }
  return y + '-' + mture + '-' + dture
}

/**
 * 月份的计算
 * @description 获得前一月的数据
 * @param
 */
 export function getMonthStr () {
  const dd = new Date();
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1; // 获取当前月份的日期
  let mture;
  m < 10 ? mture = '0' + m : mture = m;
  return y + '-' + mture
}

/**
 * 参数转换
 * @description 结算金额请求参数转换
 * @param params
 */
 export function getNewObject (params: {yearMonth: string}) {
  const newParams = Object.assign({
    year: '',
    month: ''
  }, params)
  const yearMonthArr = params.yearMonth.split('-')
  newParams.year = yearMonthArr[0]
  newParams.month = yearMonthArr[1]
  return newParams
}
