
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import Tips from '@/components/tips/tips.vue'

import {
  settlementFormTy,
  settlementFormRulesData,
  SettlementService
} from './settlement.service'

import { accAdd } from '@/utils/add'
import { getMonthStr, getNewObject } from '@/utils/time'
import { ExportExcel } from '@/utils/csv'

import { ElMessage } from 'element-plus'

import { SoftService, channelSoftInfosTY } from '@/services/soft.service'

export default defineComponent({
  name: '',
  components: {
    MainLayout,
    Tips,
  },
  setup() {
    const injector = useReflectiveInjector([SettlementService])
    const service = injector.get(SettlementService)
    const softService = injector.get(SoftService)

    /* 是否展示导出excel */
    const showUpExcel = computed(() => {
      if (settlementTableData.value.length > 0) {
        return true
      }
      return false
    })

    const settlementForm:settlementFormTy = reactive({
      soft_type: 0,
      yearMonth: getMonthStr()
    })
    let setTimeFunc: any = null
    /* 业务名称 */
    const channelSoftInfos = ref<channelSoftInfosTY[]>([] as channelSoftInfosTY[])
    /* 订阅业务名称 */
    const subscription = softService.channelSoftInfos.subscribe(list => {
      if (list.length > 0) {
        channelSoftInfos.value = list
        settlementForm.soft_type = list[0].softid
        setTimeFunc = setTimeout(() => { // 表单校验
          onSubmit()
        },0)
      }
    })
    onUnmounted(() => {
      // 组件销毁时，取消订阅
      subscription.unsubscribe()
      if (setTimeFunc) {
        clearTimeout(setTimeFunc)
      }
    })
    /* 表单规范 */
    const settlementFormRules =  settlementFormRulesData
    const settlementFormRef = ref()
    /* 查询 */
    function onSubmit () {
      settlementFormRef.value.validate((valid: boolean) => {
        if (!valid) return // 校验表单
        service.doGetSttlementInfo(settlementForm).then((response) => {
          settlementTableData.value = response?.data || []
          ElMessage.success('成功查询数据')
        }).catch((err) => {
          ElMessage.error(`查询数据失败,${err.msg}`)
        })
      })
    }
    /* 表尾求和 */
    function getSummaries (param:any) {
      const { columns, data } = param
      const sums: any[] = []
      columns.forEach((column: any, index: any) => {
        if (index === 0) {
          sums[index] = '累计'
          return
        }
        const property = column.property
        if (property !== 'cash') {
          sums[index] = '--'
          return
        }
        const values = data.map((item: any) => item[property])
        if (!values.every((value: any) => isNaN(value))) { // 所有元素都不是NaN
          sums[index] = ` ${values.reduce((prev:any, curr: any) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return accAdd(prev, curr).toFixed(2)
            }
            return prev
          }, 0)}`
        } else {
          sums[index] = '--'
        }
      })

      return sums
    }

    /* 导出EXCEL */
    function upExcel () {
      ExportExcel('/get_settle_price_info.csv', getNewObject(settlementForm))
    }

    /* 表格数据 */
    const settlementTableData = ref<any>([])

    return {
      showUpExcel,
      settlementForm,
      channelSoftInfos,
      settlementFormRef,
      settlementFormRules,
      onSubmit,
      upExcel,
      getSummaries,
      settlementTableData,
    };
  },
});
